
import { dispatchUserEdit } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})

export default class SignUpStepType extends Vue {
  public userType = 'client';
  public loadingState: any = {
    page: true,
  };

  public mounted() {
    const isLoggedIn = readIsLoggedIn(this.$store);
    if (!isLoggedIn) {
      this.$router.push('/sign-up');
    }
    this.loadingState.page = false;
  }

  public async submit() {
    const payload = new FormData();
    payload.append("user_type", this.userType);
    const result = await dispatchUserEdit(this.$store, payload);
    if (result) {
      this.$router.push(`/sign-up/${this.userType}/`);
    } else {
      // @ts-ignore
      this.$toast.error(this.$t('Save error'), {
      duration: 3000, position: 'bottom-right',
      });
    }
  }

}
